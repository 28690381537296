import ProxyHome from './proxy-home';
// import ProxyRules from './proxy-rules';
// import ProxyNewSubordinates from './p';

export default ProxyHome;

export {}; // ProxyRules,
// ProxyCommissionDetail,
// ProxyCommissionDetailUser,
// ProxyTeamReport,
// ProxyNewUser,
// ProxyTeamReportSearch,
// ProxyNewSubordinates,
